import dynamic from "next/dynamic";
import Link from "next/link";
import {
  Stack,
  ListItemText,
  Typography,
  Box,
  Avatar,
  Fade,
  IconButton
} from "@mui/material";
import {
  DeleteOutline as DeleteIcon,
  CopyAll as CopyIcon,
  ChatBubbleOutline as SentIcon,
  MarkChatReadOutlined as ReceivedIcon,
  SmsOutlined as ThinkingIcon,
  SpeakerNotesOutlined as SendingIcon
} from "@mui/icons-material";
import Long1Icon from "@mui-symbols-material/w400/ChatInfoOutlined";
import Long2Icon from "@mui-symbols-material/w400/ShareReviewsOutlined";
import { BeatLoader } from "react-spinners";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import theme from "react-syntax-highlighter/dist/cjs/styles/hljs/a11y-dark";

const Markdown = dynamic(() => import("react-markdown"));
const SyntaxHighlighter = dynamic(() => import("react-syntax-highlighter"));

import { useStore } from "../../../service/mobx";
import { AvatarGroup } from ".";

import "katex/dist/katex.min.css";

function MessageBytez({ id, text, step }) {
  const loadingMessage = !text;

  return (
    <div>
      <Stack
        height={24}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <AvatarGroup Avatar={BytezAvatar} name="Bytez" />
        <Fade mountOnEnter unmountOnExit appear in={loadingMessage}>
          <div>
            <Step step={step} />
          </div>
        </Fade>
        <Fade mountOnEnter appear in={loadingMessage === false}>
          <Box width="100%">
            <Options messageId={id} />
          </Box>
        </Fade>
      </Stack>
      <Box width="100%" display="flex">
        <Box
          borderRadius={theme => theme.shape.lg.round}
          sx={{
            display: "flex",
            width: { compact: "100%", expanded: "unset" },
            justifyContent: "flex-start",
            flexDirection: "column",
            color: "surface.on.color",
            wordBreak: "break-word",
            typography: {
              compact: "bodyMd",
              large: "bodyLg"
            },
            maxWidth: { compact: "unset", expanded: "calc(100% - 32px)" },
            "& a": {
              color: "primary.color",
              textDecoration: "none",
              typography: { compact: "titleSm", large: "titleMd" }
            },
            "& li p": {
              pb: 0.5
            },
            "& .katex": {
              font: "unset"
            }
          }}
        >
          {loadingMessage ? (
            <ListItemText sx={{ my: "auto", pt: 1 }}>
              <BeatLoader size={4} speedMultiplier={0.5} />
            </ListItemText>
          ) : (
            <Markdown
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex]}
              components={{ a: Anchor, code: Code }}
            >
              {text}
            </Markdown>
          )}
        </Box>
      </Box>
    </div>
  );
}
const BytezAvatar = () => (
  <Avatar
    sx={{
      width: 24,
      height: 24,
      typography: "brand",
      fontWeight: "bolder",
      bgcolor: "surface.inverse.color",
      color: "surface.inverse.on.color"
    }}
  >
    b
  </Avatar>
);

export default MessageBytez;
export function CodeBlock({ language, code }) {
  const { snackbar } = useStore();

  return (
    <Box
      bgcolor="surface.inverse.color"
      sx={theme => ({
        borderRadius: theme.shape.md.round,
        "& > pre": {
          m: 0,
          pl: "12px !important",
          bgcolor: theme.palette.surface.inverse.color + " !important"
        },
        "& *": {
          fontSize: 12,
          typography: "code",
          borderBottomLeftRadius: "inherit",
          borderBottomRightRadius: "inherit"
        }
      })}
    >
      <Stack
        pl={1.5}
        direction="row"
        alignItems="center"
        color="surface.inverse.on"
        justifyContent="space-between"
      >
        <Typography variant="code">{language}</Typography>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(code);
            snackbar.notify({ text: "Copied to clipboard" });
          }}
        >
          <CopyIcon
            sx={{ color: "surface.inverse.on", height: 24, width: 24 }}
          />
        </IconButton>
      </Stack>
      <SyntaxHighlighter language={language} style={theme}>
        {code}
      </SyntaxHighlighter>
    </Box>
  );
}

function Step({ step = { sent: 1 } }) {
  const icon = {
    sent: SentIcon,
    received: ReceivedIcon,
    thinking: ThinkingIcon,
    researching: Long1Icon,
    considering: Long2Icon,
    replying: SendingIcon
  };
  const currentStep = Object.keys(step).pop();
  const Icon = icon[currentStep];

  return (
    <Stack
      spacing={1}
      px={1}
      border={1}
      height={24}
      direction="row"
      alignItems="center"
      borderColor="outline.variant"
      bgcolor="secondary.container"
      borderRadius={theme => theme.shape.xs.round}
    >
      <Typography variant="labelSm" color="secondary.on.container">
        {currentStep}
      </Typography>
      <Icon sx={{ color: "secondary.on.container", width: 16, height: 16 }} />
    </Stack>
  );
}
function Options({ messageId }) {
  const { agent } = useStore();

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-end"
      sx={{
        opacity: 0,
        "&:hover": {
          opacity: 1
        }
      }}
    >
      <IconButton
        size="small"
        aria-label="Delete"
        onClick={() => agent.message.delete(messageId)}
      >
        <DeleteIcon
          sx={{ color: "surface.on.variant", height: 18, width: 18 }}
        />
      </IconButton>
    </Stack>
  );
}
const Anchor = ({ children, href }) => (
  <Typography
    href={href.includes("bytez.com") ? new URL(href).pathname : href}
    component={
      href.includes("bytez.com") || href.includes("http") === false ? Link : "a"
    }
  >
    {children}
  </Typography>
);
function Code({ children, className, node, ...rest }) {
  const language = className?.split("language-")[1];

  return language ? (
    <CodeBlock language={language} code={children} />
  ) : (
    <code {...rest} className={className}>
      {children}
    </code>
  );
}
const isLaTeX = / /.test.bind(
  /\\(documentclass|usepackage|begin|end|section|subsection|title|author|date|maketitle|textbf|textit|item|cite|ref|label|includegraphics|frac|sum|alpha|beta|gamma|delta|epsilon|theta|lambda|mu|pi|rho|sigma|phi|omega|text|\[|\]|\(|\)|\^|_|\\|,|\s)*(\$|\{|\}|\\\()|(\$.*?\$)|(\$\$.*?\$\$)/
);

export const RenderPaperTitle = ({ paperTitle }) =>
  isLaTeX(paperTitle) ? (
    <Box
      sx={{
        "& .katex": {
          font: "unset"
        }
      }}
    >
      <Markdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={{ p: "span" }}
      >
        {paperTitle}
      </Markdown>
    </Box>
  ) : (
    paperTitle
  );
