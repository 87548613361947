"use client";
import { useState } from "react";
import { Tooltip } from "@mui/material";

import { useStore, observer } from "../../../src/service/mobx";

function Popup({
  eventName = "",
  disable = false,
  component,
  Component,
  children,
  onEntering,
  minWidth = 320,
  maxWidth = 480,
  disableMobile = false,
  enterTouchDelay = 500,
  ...props
}) {
  const [placement, setPlacement] = useState("right");
  const [remounted, remount] = useState();
  const { analytics, bottomSheet, device } = useStore();
  const isTouch =
    device.isPhone || (device.isTablet && navigator.maxTouchPoints !== 0);

  return disable ? (
    children
  ) : (
    <Tooltip
      placement={placement}
      leaveDelay={500}
      title={isTouch ? "" : component || <Component />}
      onOpen={
        isTouch
          ? event => {
              if (!disableMobile) {
                if (event.cancelable) {
                  event.preventDefault();
                }
                bottomSheet.configure(Component);
                analytics.track.event(`Popup ${eventName}`.trim());

                return false;
              }
            }
          : event => {
              const element = event.target;
              const percentX =
                element?.offsetParent?.offsetLeft /
                element?.offsetParent?.offsetParent?.offsetWidth;

              setPlacement(percentX < 0.5 ? "right" : "left");
              analytics.track.event(`Popup ${eventName}`.trim());
            }
      }
      enterTouchDelay={enterTouchDelay}
      onTouchEndCapture={
        isTouch
          ? event => {
              if (event.cancelable) {
                event.preventDefault();
              }
            }
          : undefined
      }
      componentsProps={{
        transition: {
          timeout: { enter: 300, exit: 250 },
          onExited: () => remount(),
          onEntered: () => setTimeout(remount, 300, true),
          onEntering
        },
        popper: remounted
          ? { sx: { transition: theme => theme.transitions.create("all") } }
          : undefined,
        tooltip: {
          sx: isTouch
            ? { display: "none" }
            : theme => ({
                p: 0,
                minWidth,
                maxWidth,
                overflow: "overlay",
                boxShadow: theme.elevation[1],
                borderRadius: theme.shape.md.round,
                bgcolor: "surface.container.lowest"
              })
        }
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
}

export default observer(Popup);
